<template>
  <div class="widgetContainer widgetContainer--scrollable full-width fund">
    <div class="widgetContainer__body">
      <div class="fund__push-funds">
        <div class="fund__push-funds__headers">
          <p class="top-row main-title">
            {{ $t('fund_row_title') }}
          </p>
          <span>{{ $t('fund_desc_short') }}</span>
        </div>
        <div class="fund__push-funds__numbers">
          <div class="top-row">
            <p>{{ $t('acc_detail_num_title') }}</p>
            <span>{{ getSelectedAccount.accountNumber }}</span>
            <span class="icon-copy" @click="copyAction(getSelectedAccount.accountNumber, $t('acc_detail_num_title'))" />
          </div>
          <div class="top-row">
            <p>{{ $t('acc_detail_rout_title') }}</p>
            <span>{{ getSelectedAccount.routingNumber }}</span>
            <span class="icon-copy" @click="copyAction(getSelectedAccount.routingNumber, $t('acc_detail_rout_title'))" />
          </div>
          <div class="top-row">
            <p>Type</p>
            <span>{{ accountType }}</span>
          </div>
          <div class="top-row">
            <p>Bank</p>
            <span>{{ getSelectedAccount.sponsorBankName }}</span>
          </div>
        </div>
      </div>

      <div class="fund__move-in">
        <h1 class="header-1">
          {{ $t('fund_account_tittle') }}
        </h1>
        <div class="listWithActionsv2">
          <ul>
            <li
              @click="showPullFunds = true"
              v-if="isPullFundsEnabled">
              <div class="details">
                <p class="label">
                  {{ $t('pull_fund_row_title') }}
                </p>
                <p class="value">
                  {{ $t('pull_fund_desc_short') }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right" />
              </div>
            </li>
            <li
              @click="showDepositCheck = true"
              v-if="isDepositCheckEnabled">
              <div class="details">
                <p class="label">
                  {{ $t('RCD_depositCheck') }}
                </p>
                <p class="value">
                  {{ $t('RCD_depositCheckDesc') }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right" />
              </div>
            </li>
            <li
              @click="showDebitPull = true"
              :class="{'li-disabled': disableDebitPull}"
              v-if="isDebitPullEnabled">
              <div class="details">
                <p class="label">
                  {{ $t('RCD_debitPull') }}
                </p>
                <p class="value" v-html="$t('RCD_debitPullDesc')"/>
                <p class="value danger" v-if="disableDebitPull">
                  <strong>{{ $t('RCD_debitPull_exceeded') }}</strong>
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right" />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="fund__move-out">
        <h1 class="header-1">
          {{ $t('move_fund_row_title') }}
        </h1>
        <div class="listWithActionsv2">
          <ul>
            <li
              @click="showIntraBankFundOut = true"
              v-if="isIntrabankTransferEnabled">
              <div class="details">
                <p class="label">
                  {{ $t('move_fund_yourBank_title') }}
                </p>
                <p class="value">
                  {{ $t('move_fund_yourBank_desc') }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right" />
              </div>
            </li>
            <li
              @click="showGoToMoveFundsOut = true"
              v-if="isToAnotherBankEnabled">
              <div class="details">
                <p class="label">
                  {{ $t('move_fund_anotherBank_title') }}
                </p>
                <p class="value">
                  {{ $t('move_fund_anotherBank_desc') }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Drawer class="default-drawer" title="Pull Funds" :components="pullFunds" :show.sync="showPullFunds" v-if="showPullFunds" />
    <Drawer class="default-drawer" title="Move Funds Out" :components="moveFundsOut" :show.sync="showGoToMoveFundsOut" v-if="showGoToMoveFundsOut" />
    <Drawer class="default-drawer" title="Deposit Check" :components="depositCheck" :show.sync="showDepositCheck" v-if="showDepositCheck" />
    <Drawer class="default-drawer" title="Debit Pull" :components="debitPull" :show.sync="showDebitPull" v-if="showDebitPull" />
    <Drawer class="default-drawer" title="Move funds" :components="intraBankFundOut" :show.sync="showIntraBankFundOut" v-if="showIntraBankFundOut" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { accountType } from '@m/contact/enums';
import { textCopy } from '@/utils/general';
import Drawer from '@/components/Drawer.vue';
import { getUIConfig } from '@/utils/env';

export default {
  components: {
    Drawer
  },
  data() {
    return {
      showPullFunds: false,
      showGoToMoveFundsOut: false,
      showDepositCheck: false,
      showDebitPull: false,
      disableDebitPull: false,
      showIntraBankFundOut: false,
      isPullFundsEnabled: getUIConfig('isPullFundsEnabled'),
      isIntrabankTransferEnabled: getUIConfig('isIntrabankTransferEnabled'),
      isToAnotherBankEnabled: getUIConfig('isToAnotherBankEnabled'),
      isDepositCheckEnabled: getUIConfig('isDepositCheckEnabled'),
      isDebitPullEnabled: getUIConfig('isDepositCheckEnabled'),
      pullFunds: [
        {
          name: 'pullFunds',
          default: true,
          component: () => import('@m/fund/views/PullFunds.vue')
        },
        {
          name: 'pullFundsAmount',
          component: () => import('@m/fund/views/PullFundsAmount.vue')
        },
        {
          name: 'pullFundsSuccess',
          component: () => import('@m/fund/views/PullFundsSuccess.vue')
        }
      ],
      intraBankFundOut: [
        {
          name: 'intraBankFundOut',
          default: true,
          component: () => import('@m/fund/views/IntrabankFundOut.vue')
        },
        {
          name: 'intraBankFundOutSuccess',
          component: () => import('@m/fund/views/IntrabankFundOutSuccess.vue')
        }
      ],
      moveFundsOut: [
        {
          name: 'moveFundsOut',
          default: true,
          component: () => import('@m/fund/views/MoveFundsOut.vue')
        },
        {
          name: 'moveFundsOutAmount',
          component: () => import('@m/fund/views/MoveFundsOutAmount.vue')
        },
        {
          name: 'moveFundsOutSuccess',
          component: () => import('@m/fund/views/MoveFundsOutSuccess.vue')
        }
      ],
      depositCheck:[
        {
          name: 'CheckDeposit-actions',
          default: true,
          component: () => import('@m/check-deposit/views/CheckDepositAction.vue')
        },
        {
          name: 'CheckDeposit-contacts',
          component: () => import('@m/check-deposit/views/Contacts.vue')
        },
        {
          name: 'CheckDeposit-status',
          component: () => import('@m/check-deposit/views/CheckDepositStatus.vue')
        },
        {
          name: 'CheckDeposit-form',
          component: () => import('@m/check-deposit/views/CheckDeposit.vue')
        },
        {
          name: 'CheckDeposit-preview',
          component: () => import('@m/check-deposit/views/CheckDepositPreview.vue')
        },
        {
          name: 'CheckDeposit-success',
          component: () => import('@m/check-deposit/views/CheckDepositSuccess.vue')
        },
        {
          name: 'CheckDeposit-newContact',
          data: {
            page: 'check-deposit'
          },
          component: () => import('@m/contact/views/ContactDetailForm.vue')
        },
        {
          name: 'contact-success',
          data: {
            page: 'check-deposit'
          },
          component: () => import('@m/contact/views/ContactCreatedSuccess.vue')
        },
      ],
      debitPull: [
        {
          name: 'DebitPull-contacts',
          default: true,
          component: () => import('@m/fund/views/DebitPullContacts.vue')
        },
        {
          name: 'DebitPull-form',
          component: () => import('@m/fund/views/DebitPullForm.vue')
        },
        {
          name: 'DebitPull-amount',
          component: () => import('@m/fund/views/DebitPullAmount.vue')
        },
        {
          name: 'DebitPull-success',
          component: () => import('@m/fund/views/DebitPullSuccess.vue')
        }
      ]
    };
  },
  computed: {
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('contact', ['getSelectedContactData']),
    ...mapGetters('transaction', ['getRecentTransactionsByType']),
    accountType() {
      return accountType[this.getSelectedAccount.type];
    }
  },
  async created() {
    const loader = this.showLoader();
    const accountId = this.getSelectedAccount.id;
    await this.fetchRecentTransactionsByTransferType({accountId});
    const txnDate = this.getRecentTransactionsByType[0]?.txnDate;

    if (this.isToday(new Date(txnDate))) {
      this.disableDebitPull = true;
    }

    loader.close();
  },
  methods: {
    ...mapActions('pay', ['intrabankPay']),
    ...mapActions('card', ['listAllCardByAccount']),
    ...mapActions('transaction', ['fetchRecentTransactionsByTransferType']),
    copyAction(textToBeCopied, type) {
      const that = this;
      textCopy(textToBeCopied, function (e) {
        if (e) {
          that.$apiErrorMessage(`Unable to copy ${type}`);
        } else {
          that.$notify({
            title: 'Success',
            message: `${type} copied`,
            type: 'success'
          });
        }
      });
    },
    isToday(date) {
      const today = new Date();

      return today.getFullYear() === date.getFullYear() &&
          today.getMonth() === date.getMonth() &&
          today.getDate() === date.getDate();
    }
  }
};
</script>
<style lang="scss">
.fund {
  max-height: none;
  
  &__container {
    border-radius: var(--radius);
    background-color: #fff;
    padding: 24px 16px 16px 16px;
    margin-bottom: 24px;

    .add {
      border-bottom: 1px solid var(--branding-sidebar-border);;
      padding-bottom: 20px;
      display: flex;
      transition: 0.2s;

      &:hover {
        border-color: var(--light-grey);
      }

      .icon {
        font-size: 48px;
        margin-right: 16px;
      }

      .header-2 {
        padding-bottom: 8px;
      }

      .fund-desc {
        color: var(--branding);
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  &__header {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding: 6px 0;
  }

  &__details {
    .contact-info {
      margin-bottom: 0px;
      border-top: 1px solid var(--branding-sidebar-border);;
      padding: 16px 0 14px 0;
      margin: 23px 0 0 0;

      li {
        position: relative;
      }

      .copy {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        cursor: pointer;
      }

      .value {
        text-overflow: ellipsis;
        max-width: 45%;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
      }
    }
  }

  &__push-funds {
    background-color: var(--branding-primary);
    display: flex;
    min-height: 117px;
    border-radius: var(--radius);
    padding: 34px 64px;
    margin: 20px 0 32px 0;
    justify-content: space-between;

    @media screen and (max-width: 992px) {
      flex-direction: column;
      padding: 34px 34px;
    }

    @media screen and (max-width: 768px) {
      padding: 24px 17px;
      margin-top: 0;
    }

    > div {
      display: flex;
      flex-direction: column;
    }

    .main-title {
      padding-bottom: 12px;
    }

    .top-row {
      display: flex;
      align-items: center;
      position: relative;
    }

    p, span {
      color: #fff;
    }

    &__headers {
      display: flex;
      justify-content: center;

      p {
        font-size: 24px;
        display: flex;
        align-items: center;
      }

      span {
        padding-right: 7px;
        font-size: 14px;
        display: inline-block;
        max-width: 270px;
        line-height: 18px;
      }
    }

    &__numbers {
      position: relative;

      @media screen and (max-width: 992px) {
        margin-top: 24px;
      }

      .top-row {
        padding: 8px 100px 8px 0;

        @media screen and (max-width: 768px) {
          padding: 8px 0 8px 0;
        }
      }
      
      .icon-copy {
        cursor: pointer;
        position: absolute;
        top: 7px;
        right: 0;
        opacity: 0.6;
        transition: .3s;

        @media screen and (max-width: 992px) {
          position: static;
          margin-left: 20px;
        }

        &:hover {
          opacity: 1;
        }
      }

      p {
        font-size: 14px;
        min-width: 134px;
        font-weight: 600;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .fund__move-in,
  .fund__move-out {
    width: 48%;
    flex-direction: column;
    display: inline-flex;

    @media screen and (max-width: 992px) {
      width: 100%;
    }

    .listWithActionsv2 {
      ul {
        li {
          .details {
            .label {
              font-size: 20px;
              padding-bottom: 6px;
            }

            .danger {
              color: var(--color-red);
            }
          }

          &.li-disabled {
            pointer-events: none;
            opacity: .6;
          }
        }
      }
    }
  }

  .fund__move-in {
    margin-right: 4%;
  }

  .purpose-input {
    outline: none;
    border: none;
    text-align: right;
    input {
      text-align: right;
    }
  }

  .back {
    cursor: pointer;
    display: flex;
    align-items: center;

    .icon-arrow-left {
      font-size: 18px;
    }

    p {
      color: #3c3c43;
      font-size: 17px;
      padding-left: 6px;
    }
  }
}
</style>
